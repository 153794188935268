<template>
  <div class="my-1">
    <b-row
      v-for="(rows, index) of seatMap"
      :key="`row-wrap-${index}`"
      :class="`flex-nowrap
      ${index === 0 ? 'mb-3' :''}
      `"
    >
      <div
        v-for="(colSeats, indexColSeat) of rows"
        :key="`row-seat-${indexColSeat}`"
        :class="`flex-nowrap px-2 ${colSeats === 'block' ? 'd-flex-center' : ''}`"
      >
        <template v-if="colSeats !== 'block'">
          <div
            v-for="(cols, indexCols) of colSeats"
            :key="`cols-seat-${indexCols}`"
            class="d-flex"
          >
            <div
              v-for="seat of cols"
              :key="seat"
            >
              <TicketSeatButton
                :seat="resolveDataSeat(availSeats, seat, itineraryIndex)"
                :isRight="isRightSeat(seat)"
              />
            </div>
          </div>
        </template>

        <div
          v-else
          class="bg-secondary d-flex-center"
          style="width: 38px; height: 50px"
        />
      </div>
    </b-row>
  </div>
</template>

<!-- SEAT -->
<script>
import { BRow } from 'bootstrap-vue'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BRow,
    TicketSeatButton: () => import('./TicketSeatButton.vue'),
  },
  props: {
    availSeats: {
      type: Array,
      default: () => [],
    },
    itineraryIndex: {
      type: Number,
      default: () => 0,
    },
  },
  setup() {
    const {
      resolveDataSeat,
    } = useTrainHandle()
    function isRightSeat(index) {
      return index > 32
    }

    const seatMap = [
      [
        [
          [4, 8, 12, 16, 20, 24, 28, 32],
          [3, 7, 11, 15, 19, 23, 27, 31],
        ],
        'block',
        [
          [36, 40, 44, 48, 52, 56, 60],
          [35, 39, 43, 47, 51, 55, 59],
        ],
      ],
      [
        [
          [2, 6, 10, 14, 18, 22, 26, 30],
          [1, 5, 9, 13, 17, 21, 25, 29],
        ],
        'block',
        [
          [34, 38, 42, 46, 50, 54, 58, 62],
          [33, 37, 41, 45, 49, 53, 57, 61],
        ],
      ],
    ]

    return {
      seatMap,
      isRightSeat,
      resolveDataSeat,
    }
  },
}
</script>

<style lang="">

</style>
